import {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query'

export type QueryOptions<
  Data = unknown,
  Error = unknown,
  SelectedData = Data,
  Key extends QueryKey = QueryKey
> = Omit<
  UseQueryOptions<Data, Error, SelectedData, Key>,
  'queryKey' | 'queryFn'
>

export const useQueryFactory =
  <
    TQueryFnData = unknown,
    TError = unknown,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey
  >(
    queryKey: TQueryKey,
    queryFn: QueryFunction<TQueryFnData, TQueryKey>
  ) =>
  <SelectType = TData>(
    options?: QueryOptions<TQueryFnData, TError, SelectType, TQueryKey>
  ) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useQuery(queryKey, queryFn, options)

export const useMutationFactory =
  <
    TMutationFnData = unknown,
    TMutationError = unknown,
    TVariables = unknown,
    TContext = unknown
  >(
    mutationFn: MutationFunction<TMutationFnData, TVariables>
  ) =>
  (
    options?: UseMutationOptions<
      TMutationFnData,
      TMutationError,
      TVariables,
      TContext
    >
  ) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMutation(mutationFn, options)
