import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit'

import amplitudeReducer from '@reduxStore/reducers/amplitude/amplitudeSlice'
import challengesReducer from '@reduxStore/reducers/challenges/challengesSlice'
import CommonReducer from '@reduxStore/reducers/common/commonSlice'
import coursesReducer from '@reduxStore/reducers/courses/coursesSlice'
import helpersReducer from '@reduxStore/reducers/helpers/helpersSlice'
import profileReducer from '@reduxStore/reducers/profile/profileSlice'
import quizzReducer from '@reduxStore/reducers/quizz/quizzSlice'
import sourceBusterReducer from '@reduxStore/reducers/sourcebuster/sourceBusterSlice'
import themeReducer from '@reduxStore/reducers/theme/themeSlice'

import { LIVE_MODE } from '@constants'

export const store = configureStore({
  reducer: {
    amplitude: amplitudeReducer,
    courses: coursesReducer,
    profile: profileReducer,
    helpers: helpersReducer,
    common: CommonReducer,
    quizz: quizzReducer,
    theme: themeReducer,
    sourceBuster: sourceBusterReducer,
    challenges: challengesReducer,
  },
  devTools: !LIVE_MODE,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
