import axios from 'axios'

import { useQueryFactory } from '@hooks/dataFetching/useQueryFactory'

type ResultCountry = {
  as: string
  city: string
  country: string
  countryCode: string
  isp: string
  lat: number
  lon: number
  org: string
  query: string
  region: string
  regionName: string
  status: string
  timezone: string
  zip: string
}

const placeholder = {
  as: '',
  city: '',
  country: '',
  countryCode: '',
  isp: '',
  lat: 0,
  lon: 0,
  org: '',
  query: '',
  region: '',
  regionName: '',
  status: '',
  timezone: '',
  zip: '',
}

export const getCountry = async (): Promise<ResultCountry> => {
  const data = await axios.get(
    `https://pro.ip-api.com/json?key=${process.env.NEXT_PUBLIC_IP_API_KEY}`
  )
  return data?.data
}

export const useQueryCountry = (): ResultCountry => {
  const { data } = useQueryFactory<ResultCountry>(
    ['country'],
    getCountry
  )({
    cacheTime: Infinity,
    staleTime: Infinity,
    placeholderData: placeholder,
    refetchOnWindowFocus: false,
  })

  return data || placeholder
}
