import { Trans } from '@lingui/macro'
import { FC, useEffect, useState } from 'react'

// use direct import for svgs instead of using Icon component
// to have them in offline mode
import CloseIcon from '@promova/ui/icons/ui-kit/close.svg'
import WifiSlashIcon from '@promova/ui/icons/ui-kit/wifi-slash.svg'

import SnackBar from '@elements/SnackBar/SnackBar'

import styles from './no_connection.module.scss'

const TIMEOUT = 10000 // 10 seconds
let timeout: ReturnType<typeof setTimeout>

const NoConnectionAlert: FC = () => {
  const [showMessage, setShowMessage] = useState(false)

  useEffect(() => {
    const controller = new AbortController()

    window.addEventListener(
      'online',
      () => {
        setShowMessage(false)
        clearTimeout(timeout)
      },
      { signal: controller.signal }
    )

    window.addEventListener(
      'offline',
      () => {
        setShowMessage(true)
        timeout = setTimeout(() => {}, TIMEOUT)
      },
      { signal: controller.signal }
    )

    return () => {
      controller.abort()
      clearTimeout(timeout)
    }
  }, [])

  const handleClose = () => {
    setShowMessage(false)
    clearTimeout(timeout)
  }

  if (showMessage) {
    return (
      <SnackBar className={styles.no_connection_error}>
        <div className={styles.row}>
          <WifiSlashIcon className={styles.icon} />
          <div>
            <div className={styles.title}>
              <Trans>No internet connection</Trans>
            </div>
            <div className={styles.description}>
              <Trans>Some content might be missing</Trans>
            </div>
          </div>
          <button
            type="button"
            onClick={handleClose}
            className={styles.close_btn}
          >
            <CloseIcon className={styles.close_icon} />
          </button>
        </div>
      </SnackBar>
    )
  }

  return null
}

export default NoConnectionAlert
