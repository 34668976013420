export const PAGES_URL = '/pages'

export const TERMS_OF_USE_URL = '/pages/1'
export const TERMS_OF_USE_SLUG = 'terms-of-use'
export const TERMS_OF_USE_UAE_SLUG = 'terms-of-use-1'
export const TERMS_OF_USE_US_SLUG = 'terms-of-use-us'
export const TERMS_OF_USE_UA_SLUG = 'terms-of-use-ua'
export const TERMS_OF_USE_CALIFORNIA_SLUG = 'terms-of-use-california'

export const SUBSCRIPTION_TERMS_URL = '/pages/2'
export const SUBSCRIPTION_TERMS_SLUG = 'subscription-terms'

export const PRIVACY_POLICY_URL = '/pages/3'
export const PRIVACY_POLICY_SLUG = 'privacy-policy'
export const PRIVACY_POLICY_UAE_SLUG = 'privacy-policy-1'
export const PRIVACY_POLICY_US_SLUG = 'privacy-policy-us'
export const PRIVACY_POLICY_UA_SLUG = 'privacy-policy-ua'
export const PRIVACY_POLICY_CALIFORNIA_SLUG = 'privacy-policy-california'

export const PRIVACY_POLICY_CALIFORNIA_URL = '/pages/5'
export const PRIVACY_POLICY_CALIFORNIA_UAE_URL = '/pages/13'

export const LESSONS_RULES_URL = '/pages/4'
export const LESSONS_RULES_SLUG = 'platform-guidelines'

export const ARTICLES_URL = '/articles'
export const ARTICLE_COMMENTS_URL = '/comments/api::article.article'
export const LANGUAGE_LEARNING_APP_PAGE_URL = '/language-learning-app'
export const SPEAKING_CLUB_URL = '/speaking-club'
export const MAIN_PAGE = '/main-page-v3'
export const GROUP_LESSONS_PAGE = '/group-lessons'
export const GROUP_LESSONS_CLASS = '/group-lessons-class'
export const GROUP_LESSON_PRICING_PAGE = '/group-lessons-pricing-pages'
export const ONLINE_ENGLISH_TUTOR_URL = '/online-english-tutor-v2'
export const ONLINE_ENGLISH_CLASSES_URL = '/online-english-class'
export const LEARN_ENGLISH_ONLINE_URL = '/learn-english-online'
export const GRAMMAR_TUTORS_URL = '/grammar-tutors'
export const SITEMAP_URL = '/sitemap'
export const LEVELS_URL = '/levels'
export const TEST_YOUR_ENGLISH_LEVEL_URL = '/test-your-english-level'
export const FAQ_PAGE_URL = '/faq-page'
export const FAQ_CATEGORIES_URL = '/faq-categories'
export const FAQ_TOPICS_URL = '/faqs'
export const PRICING_PAGE_URL = '/pricing-page'
export const DIA_INFO_PAGE_URL = '/diya-info-page'
export const CLASSES_URL = '/classes'
export const SKILLS_URL = '/skills'
export const ONLINE_LANGUAGE_COURSE_URL = '/online-language-course'
export const ENGLISH_EXERCISES_AND_QUIZZES_URL =
  '/english-exercises-and-quizzes'
export const FOOTER_URL = '/footer'
export const OTHER_ARTICLES_URL = '/other-articles'
export const OTHER_ARTICLES_CATEGORIES_URL = '/other-articles-categories'
export const OTHER_ARTICLE_COMMENTS_URL =
  '/comments/api::other-article.other-article'
export const WHO_WE_ARE_URL = '/who-we-are'
export const TAGS_URL = '/tags'
export const HOW_TO_PRONOUNCE = '/how-to-pronounce'
export const PART_OF_SPEECH = '/part-of-speech'
export const CONFUSING_WORD = '/confusing-word'
export const CONFUSING_WORD_SITEMAP = '/confusing-words-sitemap'
export const PART_OF_SPEECH_SITEMAP = '/part-of-speech-sitemap'
export const CONTACT_US_URL = '/contact-us'
export const APPS_URL = '/apps'
export const LANDINGS_URL = '/landings'
export const HOW_TO_PRONOUNCE_SITEMAP = '/how-to-pronounce-sitemap'
export const RULES_URL = '/ruless'
export const PRACTICE_URL = '/subjects'
export const GROUP_CLASSES_TARGET_LEVEL_MODAL =
  '/group-lessons-english-level-pop-up'
export const GROUP_LESSONS_SCHEDULE = '/group-lessons-schedules'
export const GROUP_LESSONS_LANDING = '/group-lessons-landings'
export const OUR_EDITORIAL_PROCESS_URL = '/our-editorial-process'
export const AUTHORS_URL = '/authors'
export const EXPERTS_URL = '/experts'
export const PRESS_CENTER_URL = '/press-center'
export const ONLINE_URL = '/onlines'
export const OFFER_URL = '/offers'
export const BLOG_URL = '/blog'
export const CTA_BANNER_LIST = '/cta-banner-lists'
export const CTA_MEDIA_BANNERS_LIST = '/cta-media-banner-lists'
export const CTA_EMAIL_SUBSCRIBE_LIST = '/cta-email-subscribe-lists'
export const TEST_PAGE_URL = '/test-page'
export const BUILDER_PAGES_URL = '/builders'
export const BUILDER_PAGE_LOCALIZED_URL = '/builder-localized'
export const LEARNING_URL = '/learnings'
export const SUBSCRIPTIONS_URL = '/subscriptions'
export const OUR_TUTORS_PAGE_URL = '/our-tutors-page'
export const OUR_TUTORS_PAGE_ALL_TUTORS_URL = '/tutors'
export const ENGLISH_TUTOR = '/english-tutor'
export const ENGLISH_TUTOR_SLUG = '/english-tutors-slug'
export const SYNONYM = '/synonym'
export const ANTONYM = '/antonym'

export const INDIVIDUAL_TUTOR_PAGE_URL = '/individual-tutor-page'
export const PROMOVA_IN_NUMBERS_URL = '/promova-in-number'
export const MEDIA_PRESS_URL = '/media-presses'
export const PAST_TENSE_OF_URL = '/past-tense-of'
export const PAST_TENSE_OF_SLUG_URL = '/past-tense-of-slug'
export const PAST_TENSE_OF_SITEMAP = '/past-tense-of-sitemap'
export const CTA_GEO_TIMER_WITH_BUTTON = '/cta-geo-timer-with-buttons'

export const LEARNING_LANDINGS = '/learning-landings'
export const AI_LANDING = '/ai-landings'

export const StrapiCtaBannerList = {
  COMMON_BANNERS_INFO: 'common-banners-info',
  TRY_PROMOVA: 'cta-try-promova',
  LANGUAGE_JOURNEY: 'cta-language-journey',
  CTA_WITH_RUNNING_TEXT: 'cta-with-running-text',
  DOWNLOAD_APP_QR_CODE: 'cta-download-app-qr',
  BLOG_PROMOCODE: 'cta-blog-promocode',
  APP_BANNER: 'cta-app-banner',
  BANNER_FOR_ARTICLE: 'cta-banner-for-article',
  CTA_BANNER_MOBILE_ONLY: 'cta-banner-mobile-only',
  CTA_DONT_GO_ALL_PAGES: 'cta-dont-go-all-pages',
  CTA_POPUP_ALL_PAGES: 'cta-popup-all-pages',
  CTA_TUTORING_BANNER: 'cta-tutoring-banner',
  CTA_TUTORING_POPUP: 'cta-tutoring-popup',
  CTA_TIMER_WITH_BUTTON_ALL_PAGES: 'cta-timer-with-button-all-pages',
} as const

export const CHECKOUT_URL = '/checkouts'
