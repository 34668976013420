import {
  QueryKey as QueryKeyType,
  UseInfiniteQueryOptions,
  UseMutationOptions as UseMutationOptionsType,
  UseQueryOptions as UseQueryOptionsType,
} from '@tanstack/react-query'
import { ParamsOption, RequestBodyOption } from 'openapi-fetch'

export enum QuestionTypes {
  SINGLE_CHOICE = 'single_choise',
  MULTIPLE_CHOICE = 'multiple_choise',
  SINGLE_SELECT = 'single_select',
  SINGLE_SELECT_TIMEZONE = 'single_select_timezone',
  RANGE_SLIDER_TIME = 'range_slider_time',
  SLIDER = 'slider',
  INPUT = 'input',
  QUIZ_DIVIDER = 'quiz_divider',
  LANDING = 'landing',
  WITH_CHECK_SINGLE_CHOICE = 'with_check_single_choise',
  CANCEL_TEXT_FIELD = 'cancel_textfield',
  CANCEL_EMAIL = 'cancel_email',
  GREETING = 'greeting',
  PRESENTATION = 'presentation',
  MOTIVATION = 'motivation',
  INTERRUPTION = 'interruption',
  TIME_LEARNING = 'time_learning',
  CHANGE_SUBSCRIPTION = 'change_subscription',
}

export interface Question {
  id: string
  question: string
  type: QuestionTypes
  description?: string
  category?: string
  topics?: Array<string>
  answersOptions: any
  answersOptionsToShow?: any
  image?: string
}

export interface SelectedOption {
  id?: string
  questionId: string
  selectedAnswers: Array<string>
  questionText: string
  category?: string
}

export interface UserAnswers {
  [key: string]: SelectedOption
}

export interface Answer {
  id: string
  text: string
  description: Array<string>
  icon?: string
  key?: string
  correct?: boolean
}

export type AnswersList = Array<{
  correct: boolean
  questionId?: string
  userAnswerId?: string
  correctAnswerId?: string
}>

export type OfferState = {
  quizId: string
  userCurrentLevel: string
  userGoalLevel: string
  userName: string
  wordsPerWeek: number
  flowId: string
  userId: string
  entryPoint: string
} | null

export enum ModalTypes {
  checkout = 'checkout',
  email = 'email',
}

export enum PaymentMode {
  oneTime = 'oneTime',
  subscription = 'subscription',
  twoForOneSubscription = 'twoForOneSubscription',
  noIntroSubscription = 'noIntroSubscription',
}

export enum ProductCategory {
  selfStudy = 'self study',
  tutors = 'tutors',
}

export type Currencies = 'USD' | 'EUR' | 'GBP' | 'UAH'

export type CurrencyType = {
  [key in Currencies]: {
    symbol: string
    code: string
  }
}

export interface Total {
  amount: number
  solidId?: string
  firstPayment: number
  secondPayment: number
  paymentMode: PaymentMode
  productId: string
  pandaId: string
  currencySymbol: string
  currencyCode: string
  trialPeriod?: string
  trialDuration?: string | number
  billingCyclePeriod?: string
  productDescription: string
  trialDescription?: string
  labelText?: string
  pricePerCredit?: number
  order?: number
  productCategory?: string
  rebillPayment?: number
  discountLabel?: string
}

export type UserProfile = {
  auth_user_id: string
  created_at: string
  email: string
  free_for_ukraine: boolean
  is_used_mobile_app: boolean
  learning_preferences: {
    daily_lesson_length: number
    learning_time_from: {
      hours: number
      minutes: number
    }
    learning_time_to: {
      hours: number
      minutes: number
    }
    studying_days_per_week: number
  }
  language_level: { language: string; level: string; goal: string }[]
  name: string
  phone_number: string
  photo_url: string
  time_location: string
  updated_at: string
  user_id: string
  version: string
  keeper: {
    [key: string]: {
      updated_at: string
      value: string
    }
  }
  avatar: {
    id: string
    url: string
  }
  offers: {
    work_on_mistake?: boolean
    can_buy_tutor_trial?: boolean
    show_lesson_not_booked?: boolean
    disable_tutors?: boolean
    show_group_lesson_sale?: boolean
    show_group_lesson_sale_upcoming?: boolean
    show_group_lesson_sale_past?: boolean
  }
}

export interface KeeperData {
  key: string
  payload: string
}

export type FilterQueries = {
  level?: string
  days?: string
  time?: string
  tutors?: string
}

export enum Themes {
  DEFAULT = 'default',
  NEW_YEAR = 'new_year',
}

export type ButtonType = 'filled' | 'outlined' | 'filled_inverted'

export type QueryKey<T> = string | ParamsOption<T>['params']

export type QueryOptions<
  Data = unknown,
  Error = unknown,
  SelectedData = Data,
  Key extends QueryKeyType = QueryKeyType
> = Omit<UseQueryOptionsType<Data, Error, SelectedData, Key>, 'queryFn'>

export type InfiniteQueryOptions<
  Data = unknown,
  Error = unknown,
  SelectedData = Data,
  PageData = Data,
  Key extends QueryKeyType = QueryKeyType
> = Omit<
  UseInfiniteQueryOptions<Data, Error, SelectedData, PageData, Key>,
  'queryFn'
>

export type MutationOptions<
  Data = unknown,
  Error = unknown,
  Variables = unknown,
  Context = unknown
> = Omit<UseMutationOptionsType<Data, Error, Variables, Context>, 'queryFn'>

export type UseQueryOptions<
  RequestData,
  ResponseData,
  Error = unknown,
  Key = QueryKey<RequestData>
> = RequestBodyOption<RequestData> &
  QueryOptions<ResponseData, Error, ResponseData, Key[]>

export type UseQueryOptionsWithParams<
  RequestData,
  ResponseData,
  Error = unknown,
  Key = QueryKey<RequestData>
> = ParamsOption<RequestData> &
  RequestBodyOption<RequestData> & {
    reactQuery?: QueryOptions<ResponseData, Error, ResponseData, Key[]>
  }

export type UseInfiniteQueryOptionsWithParams<
  RequestData,
  ResponseData,
  Error = unknown,
  Key = QueryKey<RequestData>
> = ParamsOption<RequestData> &
  RequestBodyOption<RequestData> & {
    reactQuery?: InfiniteQueryOptions<
      ResponseData,
      Error,
      ResponseData,
      ResponseData,
      Key[]
    >
  }

export type UseMutationOptions<
  Data = unknown,
  Error = unknown,
  Variables = unknown,
  Context = unknown
> = RequestBodyOption<Data> & MutationOptions<Data, Error, Variables, Context>

export type ArrayElement<T> = T extends (infer U)[] ? U : never

export type Pagination = {
  /** Total count of pages  */
  count: number
  /** Current page  * */
  page: number
  /** Count of items per page  */
  per_page: number
  /** Total count of items  */
  total: number
}
