import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setIsInitializeFacebook } from '@reduxStore/reducers/common/commonSlice'

import { useQueryCountry } from '@utils/useQueryCountry'

const useInitializeFacebook = (): void => {
  const { countryCode } = useQueryCountry()
  const dispatch = useDispatch()
  const { pathname, query, isReady } = useRouter()

  const isOnboardingPage = pathname === '/foxtrot/[funnel_id]'

  useEffect(() => {
    if (
      isReady &&
      (!isOnboardingPage ||
        Number(query?.screen as string) ||
        (countryCode && countryCode !== 'US'))
    ) {
      dispatch(setIsInitializeFacebook(true))
    }
  }, [countryCode, dispatch, isOnboardingPage, isReady, query?.screen])
}

export default useInitializeFacebook
