import { type Course } from '@api/courses/getActiveCourse'
import { type CourseProgress } from '@api/courses/getCourseProgress'
import { type IntroLesson } from '@api/courses/getIntroLesson'
import { type Lesson } from '@api/courses/getLesson'
import { type LessonCoverData } from '@api/courses/getLessonsForActiveCourse'
import { type DailyPlan } from '@api/dailyPlan/getDailyPlan'
import { type DailyPlanProgress } from '@api/dailyPlan/getDailyPlanProgress'

import { type ArrayElement } from '@_types/index'

export type FeedLesson =
  | (ArrayElement<Course['lessons']> & ArrayElement<CourseProgress['lessons']>)
  | (ArrayElement<ArrayElement<DailyPlan>['lessons']> &
      ArrayElement<ArrayElement<DailyPlanProgress>['lessons']>)

export type LessonStep =
  | ArrayElement<Lesson['steps']>
  | ArrayElement<IntroLesson['steps']>

export type Quiz = ArrayElement<LessonStep['quizzes']>

export type Card = Quiz['card']

export enum QuizTypes {
  CARD = 'card',
  CARD_DOMAN = 'card_doman',
  CARD_TRANSLATION = 'card_translation',
  CONTEXT = 'context',
  GRAMMAR = 'grammar',
  GRAMMAR_CARD = 'grammar_card',
  DEFINITION = 'definition',
  THIS_OR_THAT = 'this_or_that',
  CONSTRUCTOR = 'constructor',
  CONSTRUCTOR_SPACED = 'constructor_spaced',
  CONSTRUCTOR_SPACED_TWO_LINES = 'constructor_spaced_two_lines',
  SENTENCE_SPACED_OPTION = 'sentence_spaced_option',
  CONSTRUCTOR_SPACED_KEYBOARD = 'constructor_spaced_keyboard',
  SENTENCE_CONSTRUCTOR = 'sentence_constructor',
  LISTENING = 'listening',
  SPEAKING = 'speaking_ml',
  WORD_GROUP = 'word_group',
  MATCHING_PAIRS = 'matching_pairs',
  MATCHING_PAIRS_SYNONYMS = 'matching_pairs_synonyms',
  MATCHING_PAIRS_ANTONYMS = 'matching_pairs_antonyms',
  MATCHING_PAIRS_DASH = 'matching_pairs_dash',
  TRUE_OR_FALSE = 'true_or_false',
  CONSTRUCTOR_SPACED_DOMAN = 'constructor_spaced_doman',
  READING = 'reading',
  AI_CHECK = 'ai_check',
  VIDEO = 'video',
}

export enum StepStatuses {
  STARTED = 'start_learn',
  NEW = 'new',
  COMPLETED = 'completed',
  IN_PROGRESS = 'in_progress',
  FAILED = 'failed',
  LEARNED = 'learned',
}

// export type LessonStep = {
//   id: number
//   title: string
//   quizzes: Quiz[]
//   status: StepStatuses
//   is_premium?: boolean
// }

// export enum LessonTypes {
//   REVISION = 'revision',
//   SPEAKING = 'speaking',
//   TIPSTRICKS = 'tipstricks',
//   GRAMMAR = 'grammar',
//   BOOK = 'book',
//   UNKNOWN = 'unknown',
// }

export enum StepType {
  PRONUNCIATION = 'pronunciation',
  VOCABULARY = 'vocabulary',
  PRACTICE = 'practice',
  MORE_PRACTICE = 'more_practice',
  GRAMMAR = 'grammar',
  GRAMMAR_PRACTICE = 'grammar_practice',
  SPEAKING = 'speaking',
  INTRODUCTION = 'introduction',
  READING = 'reading',
  PRACTICE_WITH_AI = 'practice_with_ai',
  VIDEO = 'video',
}

//
// export type LessonStep = {
//   id: number
//   title: string
//   quizzes: Quiz[]
//   status: StepStatuses
//   is_premium?: boolean
//   type?: StepType
// }

export enum LessonTypes {
  REVISION = 'revision',
  SPEAKING = 'speaking',
  TIPSTRICKS = 'tipstricks',
  GRAMMAR = 'grammar',
  BOOK = 'book',
  UNKNOWN = 'unknown',
}

export enum LessonCover {
  VOCABULARY_BIG = 'vocabulary_big',
  VOCABULARY_SQUARE = 'vocabulary_square',
  GRAMMAR = 'grammar',
  BOOK = 'book',
  SPEAKING = 'speaking',
  READING = 'reading',
  VIDEO = 'video',
  ROLE_PLAY_SPEAKING = 'role_play_speaking',
}

export enum LessonStatus {
  STARTED = 'start_learn',
  NEW = 'new',
  COMPLETED = 'completed',
  IN_PROGRESS = 'in_progress',
  FAILED = 'failed',
}

export enum ProgressStatuses {
  LEARNED = 'learned',
  START_LEARN = 'start_learn',
  PROGRESS = 'progress',
  FAILED = 'failed',
  SKIPPED = 'skipped',
}

export enum LearnedUnitTypes {
  QUIZ = 'quiz',
  STEP = 'step',
  LESSON = 'lesson',
  CARD = 'card',
}

export type BookProgress = {
  last_position: number
  percent: number
}

export type Book = {
  author: string
  epub: string
  ebook: string
  progress: BookProgress
}

export enum LessonPlace {
  FOR_YOU = 'for_you',
  MY_PLAN = 'my_plan',
}

export type CoverProps = {
  lesson: LessonCoverData
  isActive?: boolean
  locked?: boolean
  lessonDuration?: number
}

export interface QuizProps {
  quiz: Quiz
  isActive?: boolean
  onContinue: (
    quiz: Quiz,
    status: ProgressStatuses,
    errorsCount?: number
  ) => void
  onFail: (quiz: Quiz) => void
  onPassQuiz?: (quiz: Quiz) => void
  setProgress?: (progress: { maxValue?: number; currentValue?: number }) => void
  stepNumber: number
  quizNumber: number
  stepTitle?: string
  stepQuizzesCount?: number
  stepId?: number
}

export enum ConstructorQuizStates {
  SUCCESS = 'success',
  FAIL = 'fail',
}

export type SpeakingResult = {
  result: {
    word_part: string
    phoneme: string
    is_correct: boolean
    score: number
  }[]
  score: number
  is_correct: boolean
  sentence: string
}

export interface LessonFeedback {
  course_id: string
  lesson_id: number | null
  feedback_flow: 'on_force_quit_lesson' | 'on_finish_lesson'
  title: string
  body: string
  rating: number
  platform: 'web' | 'android' | 'ios'
  user_level?: string
}

export enum ChallengeStatuses {
  NEW = 'new',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export enum ChallengeClaimedStatus {
  GROUP_CREDIT = 'group_credit',
  PREMIUM_SALE = 'premium_sale',
}

export enum ActivitiesTypes {
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate',
  ADD = 'add',
  REMOVE = 'remove',
  FINISH = 'finish',
}

export enum ActivitiesTimeUnits {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
}

export type Challenge = {
  status: ChallengeStatuses
  created_at: string
  was_completed_at?: string
  was_failed_at?: string
  received_award?: boolean
  try?: number
  activities: {
    type: ActivitiesTypes
    name: string
    created_at: string
    step: number
    time_unit?: ActivitiesTimeUnits
    status: ChallengeStatuses
    was_completed_at?: string
    was_failed_at?: string
  }[]
  claimed?: ChallengeClaimedStatus
}
