import * as amplitude from '@amplitude/analytics-browser'
import { useFeatureValue } from '@growthbook/growthbook-react'
import {
  Auth,
  User,
  getAuth,
  onAuthStateChanged,
  signInAnonymously,
} from 'firebase/auth'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { hotjar } from 'react-hotjar'

import { useAvailableProducts } from '@api/products/getAvailableProducts'

import { useAppDispatch, useAppSelector } from '@reduxStore/hooks'
import {
  setGroupLessonsSubscriptionStatus,
  setHasCoursesPremium,
  setHasTutoringSubscription,
  setIsSubscriptionFetched,
  setUserProfile,
} from '@reduxStore/reducers/profile/profileSlice'
import {
  getUserProfile,
  updateKeeper,
} from '@reduxStore/reducers/profile/profileThunks'

import { COURSE_PREMIUM_LESSONS_CONF } from '@constants_folder/profileKeeperKeys'
import {
  DEFAULT_REMOTE_CONFIG,
  PREMIUM_LESSONS_CONFIG,
} from '@constants_folder/remote_config'
import { AUTOLOGIN_ROUTE } from '@constants_folder/routes'
import {
  CookieKeys,
  IS_USER_LOGGED_OUT,
  SESSION_USER_EMAIL_PROPERTY_SEND,
} from '@constants_folder/storageKeys'

import { SubscriptionStatus } from '@_types/subscription'

import { useTheme } from '@hooks/useTheme'

import { setAmplitudeUserId, setUserProperties } from '@utils/analytics'
import clearLocalStorageAfterLogout from '@utils/clearLocalStorageAfterLogout'
import initFirebase from '@utils/firebase'

export type AuthContextType = {
  auth: Auth | null
  user: User | null
  globalUser: User | null
  loading: boolean
  updateUser: (user: User) => void
}

export const AuthContext = createContext<Partial<AuthContextType>>({})

export const useAuthContext = () => useContext(AuthContext)

const AuthProvider: FC = ({ children }) => {
  const dispatch = useAppDispatch()
  const [auth, setAuth] = useState<Auth | null>(null)
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)
  const { pathname } = useRouter()
  const isUserProductsFetchedOnce = useRef(false)
  const userProfile = useAppSelector((state) => state.profile.userProfile)

  const premiumLessonsConfig = useFeatureValue(
    PREMIUM_LESSONS_CONFIG,
    DEFAULT_REMOTE_CONFIG[PREMIUM_LESSONS_CONFIG]
  )

  const isAuthorized = Boolean(user && !user.isAnonymous)
  // switch body attribute data-theme here to have access to redux store for checking amplitudeInited
  useTheme({ enabled: isAuthorized })

  const { data: userProducts } = useAvailableProducts({ enabled: isAuthorized })

  useEffect(() => {
    const firebaseApp = initFirebase()

    if (!firebaseApp) return

    const authInstance = getAuth()

    onAuthStateChanged(authInstance, (data) => {
      const userLoggedOutCondition = !data && user
      const userNotLoggedInCondition = data && !user

      if (userLoggedOutCondition) {
        clearLocalStorageAfterLogout()
        sessionStorage.removeItem(SESSION_USER_EMAIL_PROPERTY_SEND)
        sessionStorage.setItem(IS_USER_LOGGED_OUT, 'true')
      }

      if (userNotLoggedInCondition) {
        Cookies.set(
          CookieKeys.STS_TOKEN_MANAGER,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          JSON.stringify(data?.stsTokenManager)
        )

        setUser(data)
        setLoading(false)
        setAuth(authInstance)

        return
      }

      if (pathname !== AUTOLOGIN_ROUTE && !user) {
        signInAnonymously(authInstance)
          .then((userData) => {
            setUser(userData.user)
          })
          .catch(() => {
            //
          })
      }

      setLoading(false)
      isUserProductsFetchedOnce.current = false
    })

    setAuth(authInstance)
  }, [pathname, user])

  // set amplitude user id when firebase user appears
  // and get user profile from api
  useEffect(() => {
    if (user && !user.isAnonymous) {
      setAmplitudeUserId(user.uid)
      hotjar.identify(user.uid, {})
      user.getIdToken().then((token) => {
        setTimeout(() => {
          dispatch(getUserProfile(token))
        }, 100)
      })
    } else {
      setAmplitudeUserId(undefined)
      dispatch(setUserProfile({ userProfile: {} }))
      dispatch(setHasCoursesPremium(false))
      dispatch(setIsSubscriptionFetched(false))
      dispatch(setHasTutoringSubscription(false))
      dispatch(setGroupLessonsSubscriptionStatus('none'))
    }
  }, [user, user?.isAnonymous, dispatch])

  // get user products
  useEffect(() => {
    if (userProducts && !isUserProductsFetchedOnce.current) {
      isUserProductsFetchedOnce.current = true

      const identifyEvent = new amplitude.Identify()

      const activeSubscriptions = userProducts.filter(
        (product) => product?.status === 'ok'
      )

      const activeCoursesSubscription = activeSubscriptions.find(
        (product) => product?.category === 'self study'
      )
      const activeTutorsSubscription = activeSubscriptions.find(
        (product) => product?.category === 'tutors'
      )
      const activeGroupLessonsSubscription = activeSubscriptions.find(
        (product) => product?.type === 'group_lessons'
      )

      const canceledGroupLessonsSubscription = userProducts.some(
        (product) =>
          product?.type === 'group_lessons' && product?.status !== 'ok'
      )

      if (activeSubscriptions.length === 0) {
        identifyEvent.set('subscription_status', SubscriptionStatus.FREE)
      } else {
        const isUserHasPremiumSubscription = Boolean(
          activeSubscriptions.find((sub) => !sub?.trial)
        )

        identifyEvent.set(
          'subscription_status',
          isUserHasPremiumSubscription
            ? SubscriptionStatus.PREMIUM
            : SubscriptionStatus.TRIAL
        )
      }

      dispatch(setHasTutoringSubscription(Boolean(activeTutorsSubscription)))

      if (!activeTutorsSubscription) {
        identifyEvent.set(
          'tutoring_subscription_status',
          SubscriptionStatus.FREE
        )
      } else {
        const tutoringSubscriptionStatus = activeTutorsSubscription?.trial
          ? SubscriptionStatus.TRIAL
          : SubscriptionStatus.PREMIUM

        identifyEvent.set(
          'tutoring_subscription_status',
          tutoringSubscriptionStatus
        )
      }

      if (!activeCoursesSubscription) {
        identifyEvent.set(
          'courses_subscription_status',
          SubscriptionStatus.FREE
        )

        dispatch(setHasCoursesPremium(false))
      } else {
        dispatch(setHasCoursesPremium(true))

        /*
         we have 3 subscription statuses :
         if status === ok and trial === true => 'trial'
         if status === ok and trial === false => 'premium'
         else we have 'free'
         */

        const subscriptionStatus = activeCoursesSubscription?.trial
          ? SubscriptionStatus.TRIAL
          : SubscriptionStatus.PREMIUM

        identifyEvent.set('courses_subscription_status', subscriptionStatus)
        identifyEvent.set('courses_paying_user', true)
      }

      if (activeGroupLessonsSubscription) {
        dispatch(setGroupLessonsSubscriptionStatus('active'))
      } else if (canceledGroupLessonsSubscription) {
        dispatch(setGroupLessonsSubscriptionStatus('canceled'))
      } else {
        dispatch(setGroupLessonsSubscriptionStatus('none'))
      }

      dispatch(setIsSubscriptionFetched(true))
      amplitude.identify(identifyEvent)
    }
  }, [dispatch, userProducts])
  // do not use userProfile to prevent trigger useEffect on every profile change

  useEffect(() => {
    if (Object.keys(userProfile).length === 0) return

    const isUserHasPremiumLessonConfig =
      userProfile?.keeper?.[COURSE_PREMIUM_LESSONS_CONF]?.value

    if (!user || isUserHasPremiumLessonConfig) return

    const addDataToKeeper = async () => {
      const token = await user.getIdToken()

      if (!isUserHasPremiumLessonConfig) {
        dispatch(
          updateKeeper({
            token,
            data: {
              key: COURSE_PREMIUM_LESSONS_CONF,
              payload: premiumLessonsConfig,
            },
          })
        )
      }
    }

    try {
      addDataToKeeper()
    } catch {
      //
    }
    // eslint-disable-next-line
  }, [
    user,
    userProfile?.user_id, // do not use userProfile to prevent trigger useEffect on every profile change
    premiumLessonsConfig,
    dispatch,
  ])

  // provide courses premium access for free_for_ukraine users
  useEffect(() => {
    if (Object.keys(userProfile).length > 0) {
      const freeForUkraine = Boolean(userProfile?.free_for_ukraine)

      if (freeForUkraine) {
        dispatch(setHasCoursesPremium(true))
      }

      setUserProperties({
        free_for_ukraine: freeForUkraine,
      })
    }
    // eslint-disable-next-line
  }, [userProfile?.user_id, userProfile?.free_for_ukraine, dispatch])
  // do not use userProfile to prevent trigger useEffect on every profile change

  useEffect(() => {
    const isUserEmailSend = sessionStorage.getItem(
      SESSION_USER_EMAIL_PROPERTY_SEND
    )

    if (isUserEmailSend || !userProfile?.email) return

    setUserProperties({
      user_email: userProfile?.email,
    })
  }, [userProfile?.email])

  const updateUser = useCallback((_user: User) => {
    setUser((prevUser) => Object.assign(prevUser || {}, _user))
  }, [])

  const context = useMemo(
    () => ({
      auth,
      user: user?.isAnonymous ? null : user, // do not provide anonymous user
      globalUser: user, // provide auth or anonymous user
      loading,
      updateUser,
    }),
    // eslint-disable-next-line
    [
      auth,
      user,
      user?.isAnonymous, // changes in context are not triggered without this dependency
      loading,
      updateUser,
    ]
  )

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
}

interface ExtendedProps {
  auth: AuthContextType
}

export const withAuthContext =
  <P extends object>(
    WrappedComponent: React.ComponentType<P>
  ): React.FC<Omit<P, keyof ExtendedProps>> =>
  // eslint-disable-next-line react/display-name
  (props) =>
    (
      <AuthContext.Consumer>
        {(auth) => <WrappedComponent {...(props as P)} auth={auth} />}
      </AuthContext.Consumer>
    )

export default AuthProvider
