import { Course } from '@api/courses/getActiveCourse'
import { CourseProgress } from '@api/courses/getCourseProgress'
import { Lesson } from '@api/courses/getLesson'
import { DailyPlan } from '@api/dailyPlan/getDailyPlan'
import { DailyPlanProgress } from '@api/dailyPlan/getDailyPlanProgress'

type AnalyticsData = string | number | 'no_info'

export interface CoursesState {
  coursesList: Course[]
  userCourses: Course[]
  course: Course | undefined
  courseProgress: CourseProgress | undefined
  dailyPlan: DailyPlan | undefined
  dailyPlanProgress: DailyPlanProgress | undefined
  dailyPlanActiveDay: number
  activeLevel: {
    value: string
    scroll: boolean
  }
  activeTopic: {
    value: string
    scroll: boolean
  }
  lesson: Lesson | null
  lessonAnalyticsIsSet: boolean
  lessonAnalytics: {
    lesson: AnalyticsData
    lesson_type: AnalyticsData
    course: AnalyticsData
    level: AnalyticsData
    quiz_count: AnalyticsData
    step_count: AnalyticsData
    influencer?: AnalyticsData
    userLevel: AnalyticsData
    where: AnalyticsData
  }
  lessonMistakes: number
  letters: string[]
  loading: boolean
  loadingError: boolean
  progressError: boolean
}

export enum CourseActions {
  RESET_COURSES = 'resetCourses',
  SET_COURSES_LIST = 'setCoursesList',
  SET_COURSE = 'setCourse',
  SET_LESSON = 'setLesson',
  RESET_LESSON_MISTAKES = 'resetLessonMistakes',
  ADD_LESSON_MISTAKE = 'addLessonMistake',
  SET_LESSON_STATUS = 'courses/setLessonStatus',
  SET_LESSON_ANALYTICS_DATA = 'setLessonAnalyticsData',
  RESET_LESSON_ANALYTICS_DATA = 'resetLessonAnalyticsData',
  SET_LESSON_LETTERS = 'setLessonLetters',
  GET_COURSES_LIST = 'getCoursesList',
  SET_ACTIVE_LEVEL = 'setActiveLevel',
  SET_ACTIVE_TOPIC = 'setActiveTopic',
  GET_ACTIVE_COURSE = 'getActiveCourse',
  GET_COURSE_PROGRESS = 'getCourseProgress',
  GET_DAILY_PLAN = 'getDailyPlan',
  GET_DAILY_PLAN_PROGRESS = 'getDailyPlanProgress',
  SET_DAILY_PLAN_ACTIVE_DAY = 'setDailyPlanActiveDay',
}
