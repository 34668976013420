import { useEffect, useRef, useState } from 'react'

import { useProfile } from '@api/profile/getProfile'
import { useUpdateKeeper } from '@api/profile/updateKeeper'

type Config<T> = {
  key: string
  defaultValue?: T
  enabled?: boolean
}

type UseReturnValue<T, V> = {
  value: V extends undefined ? T | never : T
  update: (value: T) => void
  isFetched: boolean
}

const useKeeper = <T>({ key, defaultValue, enabled = true }: Config<T>) => {
  const [value, setValue] = useState<T | undefined>()
  const [isFetched, setIsFetched] = useState(false)

  const { data: profile } = useProfile({ enabled })
  const keeperMutation = useUpdateKeeper()
  const prevValue = useRef<string>()

  useEffect(() => {
    const valueFromKeeper = profile?.keeper?.[key]?.value

    if (valueFromKeeper && valueFromKeeper !== prevValue.current) {
      prevValue.current = valueFromKeeper

      try {
        setValue(JSON.parse(valueFromKeeper))
      } catch (e) {
        // console.error(e)
        setValue(defaultValue)
      } finally {
        setIsFetched(true)
      }
    }

    if (!valueFromKeeper && defaultValue) {
      setValue(defaultValue)
      setIsFetched(true)
    }
    // prevent re-rendering if defaultValue is not a primitive
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, profile?.keeper])

  const update = async (payload: T) => {
    keeperMutation.mutate({
      key,
      value: JSON.stringify(payload),
    })
  }

  return { value, update, isFetched } as UseReturnValue<T, typeof defaultValue>
}

export { useKeeper }
