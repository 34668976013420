export enum AccessibilitySettingsKeys {
  DYSLEXIC = 'dyslexic',
  WHITE_NOISE = 'whiteNoise',
}

export type AccessibilitySettings = {
  [key in AccessibilitySettingsKeys]: boolean
}

export type BookReaderSettings = {
  theme: 'light' | 'dark'
  fontFamily: string
  fontSize: number
}
