import { mainHttpClient } from '@api/OpenApiClient'
import { GetResponseSchema } from '@api/schema'
import { paths } from '@api/schema/schema'

import { UseQueryOptions } from '@_types/index'

import { useQueryFactory } from '@hooks/dataFetching/useQueryFactory'

const GET_PROFILE_QUERY_KEY = '/v1/profiles'

type ProfileSchemaPath = paths[typeof GET_PROFILE_QUERY_KEY]

type Profile = GetResponseSchema<ProfileSchemaPath>

const getProfile = async () => {
  const { data } = await mainHttpClient.get(GET_PROFILE_QUERY_KEY)

  return data
}

const useProfile = (
  config?: UseQueryOptions<
    ProfileSchemaPath['get'],
    Awaited<ReturnType<typeof getProfile>>,
    unknown,
    string
  >
) => useQueryFactory([GET_PROFILE_QUERY_KEY], getProfile)(config)

export { getProfile, useProfile, type Profile, GET_PROFILE_QUERY_KEY }
