import { useFeatureValue } from '@growthbook/growthbook-react'
import { useEffect, useRef } from 'react'

import { useAppDispatch, useAppSelector } from '@reduxStore/hooks'
import { setSpecialUITheme } from '@reduxStore/reducers/theme/themeSlice'

import { ACCESSIBILITY } from '@constants_folder/profileKeeperKeys'
import {
  DEFAULT_REMOTE_CONFIG,
  USE_NEW_YEAR_UI,
} from '@constants_folder/remote_config'

import { Themes } from '@_types/index'
import {
  AccessibilitySettingsKeys,
  AccessibilitySettings as AccessibilitySettingsType,
} from '@_types/profile_keeper'

import useAddBodyClassName from '@hooks/useAddBodyClassName'
import { useKeeper } from '@hooks/useKeeper'

type Config = {
  enabled?: boolean
}

export const useTheme = (config?: Config) => {
  const dispatch = useAppDispatch()
  const { value: accessibilitySettings } = useKeeper<
    Partial<AccessibilitySettingsType>
  >({
    key: ACCESSIBILITY,
    defaultValue: {},
    enabled: config?.enabled,
  })

  const isNewYearTheme = useFeatureValue(
    USE_NEW_YEAR_UI,
    DEFAULT_REMOTE_CONFIG[USE_NEW_YEAR_UI]
  )
  const isNewYearThemeSet = useRef(false)

  if (isNewYearTheme && !isNewYearThemeSet.current) {
    isNewYearThemeSet.current = true

    dispatch(setSpecialUITheme(Themes.NEW_YEAR))
  }

  const dyslexicMode = Boolean(
    accessibilitySettings?.[AccessibilitySettingsKeys.DYSLEXIC]
  )

  const isDyslexicModeAllowed = useAppSelector(
    (state) => state.theme.isDyslexicModeAllowed
  )

  useAddBodyClassName('body_font')

  useEffect(() => {
    if (!isDyslexicModeAllowed) {
      document.body.removeAttribute('data-dyslexic')
      return
    }

    document.body.setAttribute('data-dyslexic', `${dyslexicMode}`)
  }, [dyslexicMode, isDyslexicModeAllowed])
}
