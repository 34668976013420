import { Trans } from '@lingui/macro'
import { type FC } from 'react'

import {
  type LessonStep,
  type Quiz,
  QuizTypes,
  StepType,
} from '@_types/courses'

import Grammar from '@icons/courses/grammar.svg'

export const lessonTypeIcons = new Proxy(
  {
    grammar: { Icon: () => <Grammar /> },
  },
  {
    get(target: Record<string, { Icon: FC }>, p: string): { Icon: FC } {
      return p in target ? target[p] : { Icon: () => null }
    },
  }
)

export const levelTitles: Record<string, JSX.Element> = {
  A1: <Trans>Beginner</Trans>,
  A2: <Trans>Pre-Intermediate</Trans>,
  B1: <Trans>Intermediate</Trans>,
  B2: <Trans>Upper-Intermediate</Trans>,
  C1: <Trans>Advanced</Trans>,
  C2: <Trans>Advanced</Trans>,
}

export const levelTitlesFeed: Record<string, JSX.Element> = {
  A1: <Trans>Start up</Trans>,
  A2: <Trans>Warm up</Trans>,
  B1: <Trans>Dive deeper</Trans>,
  B2: <Trans>Dive deeper</Trans>,
  C1: <Trans>Try your hardest</Trans>,
  C2: <Trans>Try your hardest</Trans>,
}

export const LANGUAGE_LEVELS = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'] as const

export const DEFAULT_LANGUAGE_LEVEL = 'A1'

export const LESSON_QUIZ_SWITCH_DELAY = 1000
export const LESSON_CONSTRUCTOR_QUIZ_SWITCH_DELAY = 2000

export const LESSON_QUIZ_CRITICAL_ERRORS_COUNT = 2

export const THEORY_STEPS = ['vocabulary', 'grammar']
export const THEORY_QUIZ_TYPES: Quiz['type'][] = [
  QuizTypes.CARD,
  QuizTypes.CARD_DOMAN,
  QuizTypes.CARD_TRANSLATION,
  QuizTypes.CONTEXT,
  QuizTypes.GRAMMAR,
  QuizTypes.GRAMMAR_CARD,
]

export const STEP_TYPES_WITH_CUSTOM_PROGRESS: LessonStep['type'][] = [
  StepType.READING,
  StepType.VIDEO,
]

export const DAILY_PLAN_FREE_DAYS_COUNT = 7

export const UKRAINE_COURSE_LOCALE = 'uk'
