import { getCountryWithCache } from '@promova/utils/useCountry'

type Props = {
  productId: string
  destination?: 'facebook' | 'google' | 'tiktok' | 'snapchat' | 'pinterest'
}

const getProductLtv = async ({
  productId,
  destination = 'facebook',
}: Props): Promise<number | null> => {
  const API_HOST = process.env.NEXT_PUBLIC_API_HOST
  try {
    const { countryCode } = await getCountryWithCache()

    const response = await fetch(
      `${API_HOST}/v1/info/${destination}/values?product_id=${productId}&country=${countryCode}`
    )

    const data = await response.json()

    return data?.value || null
  } catch {
    return null
  }
}

export default getProductLtv
