import { UserProfile } from '@_types/index'

export type UserSettings = {
  soundEffects: boolean
}

export interface ProfileState {
  userProfile: Partial<UserProfile>
  isSubscriptionFetched: boolean
  hasCoursesPremium: boolean
  hasTutoringSubscription: boolean
  groupLessonsSubscriptionStatus: 'active' | 'canceled' | 'none'
  settings: UserSettings
}

export enum ProfileActions {
  RESET_PROFILE = 'resetProfile',
  GET_USER_PROFILE = 'profile/getUserProfile',
  UPDATE_USER_KEEPER = 'profile/updateUserKeeper',
  UPDATE_USER_PROFILE = 'profile/updateUserProfile',
  SET_USER_PROFILE = 'setUserProfile',
  SET_IS_SUBSCRIPTION_FETCHED = 'setIsSubscriptionFetched',
  SET_HAS_COURSES_PREMIUM = 'setHasCoursesPremium',
  SET_HAS_TUTORING_SUBSCRIPTION = 'setHasTutoringSubscription',
  SET_GROUP_LESSONS_SUBSCRIPTION_STATUS = 'setGroupLessonsSubscriptionStatus',
  GET_USER_PRODUCTS = 'profile/getUserProducts',
  SET_USER_SETTINGS = 'profile/setUserSettings',
}
