import { PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'

import {
  ProfileActions,
  ProfileState,
} from '@reduxStore/reducers/profile/types'

export const initialState: ProfileState = {
  userProfile: {},
  hasCoursesPremium: false,
  hasTutoringSubscription: false,
  groupLessonsSubscriptionStatus: 'none',
  isSubscriptionFetched: false,
  settings: {
    soundEffects: true,
  },
}

const reducers: SliceCaseReducers<ProfileState> = {
  [ProfileActions.RESET_PROFILE]: (state: ProfileState) => ({
    ...state,
    ...initialState,
  }),
  [ProfileActions.SET_USER_PROFILE]: (
    state: ProfileState,
    action: PayloadAction<Pick<ProfileState, 'userProfile'>>
  ) => ({
    ...state,
    ...action.payload,
  }),
  [ProfileActions.SET_HAS_COURSES_PREMIUM]: (
    state: ProfileState,
    action: PayloadAction<ProfileState['hasCoursesPremium']>
  ) => ({
    ...state,
    hasCoursesPremium: action.payload,
  }),
  [ProfileActions.SET_IS_SUBSCRIPTION_FETCHED]: (
    state: ProfileState,
    action: PayloadAction<ProfileState['isSubscriptionFetched']>
  ) => ({
    ...state,
    isSubscriptionFetched: action.payload,
  }),
  [ProfileActions.SET_HAS_TUTORING_SUBSCRIPTION]: (
    state: ProfileState,
    action: PayloadAction<ProfileState['hasTutoringSubscription']>
  ) => ({
    ...state,
    hasTutoringSubscription: action.payload,
  }),
  [ProfileActions.SET_GROUP_LESSONS_SUBSCRIPTION_STATUS]: (
    state: ProfileState,
    action: PayloadAction<ProfileState['groupLessonsSubscriptionStatus']>
  ) => ({
    ...state,
    groupLessonsSubscriptionStatus: action.payload,
  }),
}

export default reducers
