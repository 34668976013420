import { useRouter } from 'next/router'
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { ACCESSIBILITY } from '@constants_folder/profileKeeperKeys'
import { PagesRoutes, ProfileRoutes } from '@constants_folder/routes'

import { AccessibilitySettings as AccessibilitySettingsType } from '@_types/profile_keeper'

import useIsBrowserTabActive from '@hooks/useIsBrowserTabActive'
import { useKeeper } from '@hooks/useKeeper'

import { AuthContext } from '@modules/Auth/AuthProvider'

type WhiteNoiseContextProps = {
  isActive: boolean
  isPlaying: boolean
  play: () => void
  pause: () => void
}

const defaultWhiteNoiseContext: WhiteNoiseContextProps = {
  isActive: false,
  isPlaying: false,
  play() {
    return null
  },
  pause() {
    return null
  },
}

const WhiteNoiseContext = createContext<WhiteNoiseContextProps>(
  defaultWhiteNoiseContext
)

export const useWhiteNoise = () => useContext(WhiteNoiseContext)

const WHITE_NOISE_AUDIO_URL = '/audio/white_noise.wav'
let interval: ReturnType<typeof setInterval>
const MAX_VOLUME = 0.05
const STEP_UP = 0.025 * MAX_VOLUME
const STEP_DOWN = 0.025 * 0.2
const INTERVAL_STEP = 50

const smoothStart = (ctx: AudioContext, gainNode: GainNode) => {
  if (interval) {
    clearInterval(interval)
  }

  const { gain } = gainNode

  let volume = 0

  gain.value = volume

  ctx.resume()

  interval = setInterval(() => {
    volume += STEP_UP

    gain.value = volume

    if (volume >= MAX_VOLUME) {
      clearInterval(interval)
    }
  }, INTERVAL_STEP)
}

const smoothStop = (ctx: AudioContext, gainNode: GainNode) => {
  if (interval) {
    clearInterval(interval)
  }

  const { gain } = gainNode

  let volume = gain.value

  interval = setInterval(() => {
    volume -= STEP_DOWN

    if (volume <= 0) {
      gain.value = 0 // to avoid rounding errors in the last iteration

      ctx.suspend()

      clearInterval(interval)
      return
    }

    gain.value = volume
  }, INTERVAL_STEP)
}

const ROUTES_WITH_WHITE_NOISE = [
  PagesRoutes.myPlan,
  PagesRoutes.forYou,
  ProfileRoutes.profile,
]

const WhiteNoiseProvider: FC = ({ children }) => {
  const isUserOnTab = useIsBrowserTabActive()
  const { user } = useContext(AuthContext)
  const { pathname } = useRouter()

  const { value: accessibilitySettings } = useKeeper<
    Partial<AccessibilitySettingsType>
  >({
    key: ACCESSIBILITY,
    defaultValue: {},
    enabled: Boolean(user),
  })

  const audio = useRef<AudioBufferSourceNode>()
  const gain = useRef<GainNode>()
  const audioContext = useRef<AudioContext>()

  const isAudioSet = useRef(false)
  const wasPlaying = useRef(false)
  const wasStarted = useRef(false)

  const [isPlaying, setIsPlaying] = useState(false)

  const isActive = Boolean(accessibilitySettings?.whiteNoise)

  const isWhiteNoiseRoute = ROUTES_WITH_WHITE_NOISE.some((route) =>
    pathname.includes(route)
  )

  useEffect(() => {
    if (!isWhiteNoiseRoute || isAudioSet.current) return

    const setAudio = async () => {
      const audioCtx = new AudioContext()

      const source = audioCtx.createBufferSource()

      try {
        const arrayBuffer = await fetch(WHITE_NOISE_AUDIO_URL).then((res) =>
          res.arrayBuffer()
        )
        source.buffer = await audioCtx.decodeAudioData(arrayBuffer)
      } catch {
        //
      }

      source.loop = true

      const gainNode = audioCtx.createGain()

      source.connect(gainNode)
      gainNode.connect(audioCtx.destination)
      audioContext.current = audioCtx
      gain.current = gainNode
      audio.current = source
      isAudioSet.current = true
    }

    setAudio()
  }, [isActive, isWhiteNoiseRoute])

  const play = useCallback(() => {
    if (!audio.current || !gain.current || !audioContext.current) return

    if (!wasStarted.current) {
      wasStarted.current = true
      audio.current.start()
    }
    smoothStart(audioContext.current, gain.current)

    setIsPlaying(true)
  }, [])

  const pause = useCallback(() => {
    if (!audio.current || !gain.current || !audioContext.current) return

    smoothStop(audioContext.current, gain.current)

    setIsPlaying(false)
  }, [])

  useEffect(() => {
    if (isActive && isUserOnTab && isWhiteNoiseRoute && wasPlaying.current) {
      wasPlaying.current = false
      play()
    }

    if (
      isActive &&
      (!isUserOnTab || !isWhiteNoiseRoute) &&
      !wasPlaying.current &&
      isPlaying
    ) {
      wasPlaying.current = true
      pause()
    }
  }, [isActive, isUserOnTab, isWhiteNoiseRoute, isPlaying, play, pause])

  const contextValue = useMemo(
    () => ({
      isActive,
      isPlaying,
      play,
      pause,
    }),
    [isActive, isPlaying, play, pause]
  )

  if (!isWhiteNoiseRoute) return children as JSX.Element

  return (
    <WhiteNoiseContext.Provider value={contextValue}>
      {children}
    </WhiteNoiseContext.Provider>
  )
}

export default WhiteNoiseProvider
