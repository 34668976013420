import axios from 'axios'

import { sendAxiosErrorAnalytics } from '@utils/analytics'

const MAX_RETRIES = 3
const RETRY_DELAY = 1000

const axiosInstance = axios.create()

const retryInterceptor = () => {
  const retryCounts: Record<string, number> = {}

  return async (error: any) => {
    const { config } = error
    const { retryConfig } = config || {}
    const {
      enable,
      maxRetries = MAX_RETRIES,
      retryDelay = RETRY_DELAY,
      shouldRetry = () => true,
    } = retryConfig || {}

    if (!enable || !config || !config.url || !shouldRetry(error)) throw error

    retryCounts[config.url] = (retryCounts[config.url] || 0) + 1

    if (retryCounts[config.url] <= maxRetries) {
      await new Promise((resolve) => {
        setTimeout(resolve, retryDelay)
      })

      return axiosInstance(config)
    }

    throw error
  }
}

axiosInstance.interceptors.response.use(
  (config) => config,
  sendAxiosErrorAnalytics
)
axiosInstance.interceptors.response.use((config) => config)

axiosInstance.interceptors.response.use(undefined, retryInterceptor())

export default axiosInstance
