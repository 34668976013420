import { mainHttpClient } from '@api/OpenApiClient'
import { GetResponseSchema, paths } from '@api/schema'

import { ArrayElement, UseQueryOptions } from '@_types/index'

import { useQueryFactory } from '@hooks/dataFetching/useQueryFactory'

const AVAILABLE_PRODUCTS_QUERY_KEY = '/v1/billing/products/available'

type Products = GetResponseSchema<paths[typeof AVAILABLE_PRODUCTS_QUERY_KEY]>

type Product = ArrayElement<Products>

const getAvailableProducts = async () => {
  const { data, error } = await mainHttpClient.get(AVAILABLE_PRODUCTS_QUERY_KEY)

  if (error) {
    throw new Error(error)
  }

  return data
}

const useAvailableProducts = (
  config?: UseQueryOptions<
    paths[typeof AVAILABLE_PRODUCTS_QUERY_KEY]['get'],
    Awaited<ReturnType<typeof getAvailableProducts>>,
    unknown,
    string
  >
) =>
  useQueryFactory([AVAILABLE_PRODUCTS_QUERY_KEY], getAvailableProducts)(config)

export {
  getAvailableProducts,
  useAvailableProducts,
  type Products,
  type Product,
  AVAILABLE_PRODUCTS_QUERY_KEY,
}
