export const enum Event {
  GROUP_LESSONS_CLICKED_CANCEL_CLASS = 'group_lessons_clicked_cancel_class',
  GROUP_LESSONS_CANCELED_CLASS = 'group_lessons_canceled_class',
  GEN_CLICKED_CONTACT_SUPPORT = 'gen_clicked_contact_support',
  GEN_SUPPORT_REQUEST_SUBMITTED = 'gen_support_request_submitted',
  GEN_JOINED_AB_TEST = 'gen_joined_ab_test',
  LANDING_CLICKED_CTA = 'landing_clicked_cta',
  GEN_CLICKED_CTA = 'gen_clicked_cta',
  TUTORING_CLICKED_GET_MORE_CREDITS = 'tutoring_clicked_get_more_credits',
  TUTORING_CLICKED_BOOK = 'tutoring_clicked_book',
  TUTORING_CLICKED_BOOK_AGAIN = 'tutoring_clicked_book_again',
  TUTORING_CLICKED_CHOOSE_SUB = 'tutoring_clicked_choose_sub',
  TUTORING_SAW_CREDITS_TOP_UP = 'tutoring_saw_credits_top_up',
  TEST_CLICKED_LATER = 'tests_clicked_later',
  TEST_CLICKED_GO = 'tests_clicked_go',
  TESTS_SEEN_TEST_POPUP = 'tests_seen_test_popup',
  GEN_CLICKED_BOOK_TUTORING_LESSON = 'gen_clicked_book_tutoring_lesson',
  TUTORING_CHOSE_LESSON_TYPE = 'tutoring_chose_lesson_type',
  TUTORING_VIEWED_OUT_OF_CREDITS = 'tutoring_viewed_out_of_credits',
  GROUP_LESSONS_CLICKED_BUY_CREDITS = 'group_lessons_clicked_buy_credits',
  GROUP_LESSONS_CLICKED_RESET_FILTERS = 'group_lessons_clicked_reset_filters',
  GROUP_LESSONS_SAW_NO_CLASSES_FOUND = 'group_lessons_saw_no_classes_found',
  GEN_SAW_ERROR_STATE = 'gen_saw_error_state',
  GROUP_LESSONS_SAW_SCHEDULE = 'group_lessons_saw_schedule',
  GROUP_LESSONS_SAW_LACK_OF_CREDITS_MESSAGE = 'group_lessons_saw_lack_of_credits_message',
  GROUP_LESSONS_SAW_CREDITS_TOP_UP = 'group_lessons_saw_credits_top_up',
  GROUP_LESSONS_CANCELED_CREDITS_PURCHASE = 'group_lessons_canceled_credits_purchase',
  GROUP_LESSONS_CLICKED_CONFIRM_BOOKING = 'group_lessons_clicked_confirm_booking',
  GROUP_LESSONS_SUCCESSFULLY_BOOKED_CLASS = 'group_lessons_successfully_booked_class',
  GROUP_LESSONS_FAILED_BOOKING = 'group_lessons_failed_booking',
  GROUP_LESSONS_CLICKED_BOOK_CLASS = 'group_lessons_clicked_book_class',
  GROUP_LESSONS_SAW_BOOKING_CONFIRMATION_SCREEN = 'group_lessons_saw_booking_confirmation_screen',
  TUTORING_CREDITS_TOP_UP_SUCCESSFUL = 'tutoring_credits_top_up_successful',
  GROUP_LESSONS_CREDITS_TOP_UP_SUCCESSFUL = 'group_lessons_credits_top_up_successful',
  TUTORING_CREDITS_TOP_UP_FAILED = 'tutoring_credits_top_up_failed',
  GROUP_LESSONS_CREDITS_TOP_UP_FAILED = 'group_lessons_credits_top_up_failed',
  REVENUE_STARTED_CHECKOUT = 'revenue_started_checkout',
  REVENUE_INITIATED_TRANSACTION = 'revenue_initiated_transaction',
  REVENUE_CLOSED_PAYMENT_FORM = 'revenue_closed_payment_form',
  REVENUE_SEEN_PRICE_SECTION = 'revenue_seen_price_section',
  REVENUE_UPSELL_VIEWED = 'revenue_upsell_viewed',
  REVENUE_UPSELL_ADDED = 'revenue_upsell_added',
  REVENUE_UPSELL_REMOVED = 'revenue_upsell_removed',
  REVENUE_UPSELL_READ_CLICKED = 'revenue_upsell_read_clicked',
  REVENUE_UPSELL_CHOSEN = 'revenue_upsell_chosen',
  REVENUE_UPSELL_SUCCESS = 'revenue_upsell_success',
  REVENUE_CLICKED_PAYMENT_TYPE = 'revenue_clicked_payment_type',
  REVENUE_PURCHASED = 'revenue_purchased',
  FUNNELS_OVERVIEW_VIEWED_DOWNSALE = 'funnels_overview_viewed_downsell',
  LANDING_PROMO_CODE_COPIED = 'landing_promo_code_copied',
  GEN_CLICKED_HEADER_BUTTON = 'gen_clicked_header_button',
  GEN_DEEPLINK_CLICKED = 'gen_deeplink_clicked',
  TEST_RESULT_GET_PLAN_BUTTON = 'test_result_get_plan_button',
  GEN_INITIATED_SIGN_IN = 'gen_initiated_sign_in',
  GEN_INITIATED_SIGN_UP = 'gen_initiated_sign_up',
  GEN_SIGN_UP_VIEWED = 'gen_sign_up_viewed',
  GEN_SIGN_IN_VIEWED = 'gen_sign_in_viewed',
  GEN_SAW_WRONG_SIGN_IN_PROVIDER = `gen_saw_wrong_sign_in_provider`,
  SETTINGS_CLICKED_LINKED_ACCOUNTS = 'settings_clicked_linked_accounts',
  SETTINGS_STARTED_ACCOUNT_LINKING = 'settings_started_account_linking',
  SETTINGS_ACCOUNT_LINKED = 'settings_account_linked',
  GEN_INITIATED_SOCIAL_AUTHORIZATION = 'gen_initiated_social_authorization',
  GEN_SIGNED_IN = 'gen_signed_in',
  GEN_SIGNED_UP = 'gen_signed_up',
  GEN_FAILED_SIGN_IN = 'gen_failed_sign_in',
  GEN_FAILED_SIGN_UP = 'gen_failed_sign_up',
  GEN_FAILED_SOCIAL_AUTHORIZATION = 'gen_failed_social_authorization',
  TUTORING_CLICKED_CANCEL_LESSON = 'tutoring_clicked_cancel_lesson',
  TUTORING_CLICKED_RESCHEDULE_LESSON = 'tutoring_clicked_reschedule_lesson',
  TUTORING_CANCELED_LESSON = 'tutoring_canceled_lesson',
  GEN_SAW_1X1_LESSONS = 'gen_saw_1x1_lessons',
  GEN_NEWSLETTER_SUBSCRIBED = 'gen_newsletter_subscribed',
  GEN_NEWSLETTER_SUBSCRIPTION_FAILED = 'gen_newsletter_subscription_failed',
  LEARNING_ANSWERED_AI_QUIZ = 'learning_answered_ai_quiz',
  TAG_CLICKED = 'tag_clicked',
  CTA_CLICKED = 'cta_clicked',
  PROFILE_NOTIFICATIONS_TAB_VIEWED = 'profile_notifications_tab_viewed',
  PROFILE_CLICKED_ON_NOTIFICATION = 'profile_clicked_on_notification',
  PROFILE_CLICKED_GO_TO_MY_PLAN = 'profile_clicked_go_to_my_plan',
  PROFILE_CLICKED_CLOSE_NOTIFICATION_TAB = 'profile_clicked_cross_on_notification_tab',
  GEN_SOUND_PLAYED = 'gen_sound_played',
  GEN_SOUND_STOPPED = 'gen_sound_stopped',
  GEN_CLICKED_MY_PLAN = 'gen_clicked_my_plan',
  SETTINGS_FEATURE_TOGGLE_UPDATED = 'settings_feature_toggle_updated',
  TUTORING_OPENED_TUTOR_INFO_PAGE = 'tutoring_opened_tutor_info_page',
  GEN_CLICKED_PLAY_VIDEO = 'gen_clicked_play_video',
  TUTORING_CLICKED_TRY_TUTORING = 'tutoring_clicked_try_tutoring',
  TUTORING_SAW_REVIEWS = 'tutoring_saw_reviews',
  TUTORING_SCROLLED_TUTORS_INFO_CARDS = 'tutoring_scrolled_tutors_info_cards',
  GROUP_LESSONS_SCROLLED_DATES = 'group_lessons_scrolled_dates',
  GROUP_LESSONS_SELECTED_DATE = 'group_lessons_selected_date',
  GEN_SAW_POPUP = 'gen_saw_popup',
  TUTORING_PICK_A_TUTOR_SCREEN_VIEWED = 'tutoring_pick_a_tutor_screen_viewed',
  TUTORING_CHOSE_TUTOR = 'tutoring_chose_tutor',
  MY_PLAN_UNIT_COMPLETED = 'my_plan_unit_completed',
  MY_PLAN_CURRENT_LEVEL_COMPLETED = 'my_plan_current_level_completed',
  MY_PLAN_LEARN_COURSE_COMPLETED = 'learn_course_completed',
  LEARNING_CLICKED_EXPLORE_COURSE = 'learning_clicked_explore_course',
  FREEMIUM_CLICKED_PREMIUM_LESSON = 'freemium_clicked_premium_lesson',
  FREEMIUM_SEEN_PREMIUM_PAYWALL = 'freemium_seen_freemium_paywall',
  FREEMIUM_CLOSED_PREMIUM_PAYWALL = 'freemium_closed_freemium_paywall',
  MY_PLAN_CLICKED_SHOW_UNITS = 'my_plan_clicked_show_units',
  MY_PLAN_CLICKED_HIDE_UNITS = 'my_plan_clicked_hide_units',
  MY_PLAN_CHANGED_UNIT = 'my_plan_changed_unit',
  BOOKS_CLICKED_BOOK = 'books_clicked_book',
  DP_VIEW = 'dp_view',
  GEN_CLICKED_PREMIUM_BANNER = 'gen_clicked_premium_banner',
  LEARNING_VIEWED_LESSON_LOADER = 'learning_viewed_lesson_loader',
  FEED_VIEW = 'feed_view',
  HOME_PICKED_LESSON_TYPE = 'home_picked_lesson_type',
  REVISION_CLICKED_MY_WORDS = 'revision_clicked_my_words',
  LEARNING_CLICKED_ROLE_PLAY_LESSON = 'learning_clicked_role_play_lesson',
}

export const enum EventProperty {
  EMAIL_NOTIFICATION = 'email_notification',
}
