// eslint-disable-next-line import/prefer-default-export

export const sleep = (time: number) =>
  new Promise((res) => {
    setTimeout(res, time)
  })

// scaling calculation
export const scalingParam = (val: number) => `calc(${val} * 100vw * 2 / 3840)`

export const replaceSpacesWithDashes = (input: string): string =>
  input.trim().replace(/[ /]/g, '-').replace(/[ʼ']/g, '').toLocaleLowerCase()
