import { createSlice } from '@reduxjs/toolkit'

import extraReducers from '@reduxStore/reducers/profile/profileExtraReducers'
import reducers, {
  initialState,
} from '@reduxStore/reducers/profile/profileReducer'

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers,
  extraReducers,
})

export const {
  resetProfile,
  setUserProfile,
  setHasCoursesPremium,
  setIsSubscriptionFetched,
  setHasTutoringSubscription,
  setGroupLessonsSubscriptionStatus,
} = profileSlice.actions

export default profileSlice.reducer
